import { makePersisted } from "@solid-primitives/storage";
import { ct, useDriverlocations } from "components";
import ArrowDown01Icon from "lucide-solid/icons/arrow-down-0-1";
import ArrowDown10Icon from "lucide-solid/icons/arrow-down-1-0";
import ChevronDownIcon from "lucide-solid/icons/chevron-down";
import ChevronRightIcon from "lucide-solid/icons/chevron-right";
import PhoneIcon from "lucide-solid/icons/phone";
import { Component, createSignal, For, Show } from "solid-js";
import { Dynamic } from "solid-js/web";
import classes from "./ClientDriverlist.module.scss";

type Props = {
  onDriverClick?: (driverId: string) => void;
};

export const ClientDriverlist: Component<Props> = (props) => {
  const { driverlocationsArray } = useDriverlocations();
  const [collapsedZipcodeIds, setCollapsedZipcodeIds] = makePersisted(createSignal<number[]>([]), {
    name: "mooovex:driverlistCollapsedZipcodeIds",
  });
  const [zipcodeOrder, setZipcodeOrder] = makePersisted(createSignal<"asc" | "desc">("asc"), {
    name: "mooovex:driverlistZipcodeOrder",
  });

  const zipcodes = () => {
    const zipcodesSet = new Set<number>();
    const zipcodesArray: Array<{ zipcodeId: number; zipcode: string; municipiality: string }> = [];

    for (const driverlocation of driverlocationsArray()) {
      if (!zipcodesSet.has(driverlocation.vehicleZipcodeId)) {
        zipcodesSet.add(driverlocation.vehicleZipcodeId);
        zipcodesArray.push({
          zipcodeId: driverlocation.vehicleZipcodeId,
          zipcode: driverlocation.vehicleZipcode,
          municipiality: driverlocation.vehicleMunicipiality,
        });
      }
    }

    zipcodesArray.sort((a, b) => parseInt(a.zipcode) - parseInt(b.zipcode));
    if (zipcodeOrder() === "desc") {
      zipcodesArray.reverse();
    }

    return zipcodesArray;
  };

  return (
    <>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-sm btn-border-dark d-flex justify-content-end align-items-center gap-2 overflow-hidden"
          onclick={() => setZipcodeOrder((prev) => (prev === "asc" ? "desc" : "asc"))}
        >
          <div class="text-truncate">{ct[zipcodeOrder() === "asc" ? "order.ascending" : "order.descending"]()}</div>
          <Dynamic
            size={20}
            component={zipcodeOrder() === "asc" ? ArrowDown01Icon : ArrowDown10Icon}
            class="flex-shrink-0"
          />
        </button>
      </div>
      <table class={classes.table}>
        <For each={zipcodes()} fallback={<div class="text-center">{ct.driverlist.noDrivers()}</div>}>
          {(zipcode) => {
            const zipcodeDrivers = () =>
              driverlocationsArray()
                .filter((driverlocation) => driverlocation.vehicleZipcodeId === zipcode.zipcodeId)
                .sort((a, b) => parseInt(a.vehicleIdentifier) - parseInt(b.vehicleIdentifier));

            const isZipcodeCollapsed = () => collapsedZipcodeIds().includes(zipcode.zipcodeId);

            function toggleZipcodeCollapsed() {
              if (isZipcodeCollapsed()) {
                setCollapsedZipcodeIds((collapsedZipcodeIds) =>
                  collapsedZipcodeIds.filter((id) => id !== zipcode.zipcodeId)
                );
              } else {
                setCollapsedZipcodeIds((collapsedZipcodeIds) => [...collapsedZipcodeIds, zipcode.zipcodeId]);
              }
            }

            return (
              <>
                <thead>
                  <tr onclick={toggleZipcodeCollapsed} class="cursor-pointer user-select-none">
                    <th style={{ width: "20px" }}>
                      <Dynamic size={20} component={isZipcodeCollapsed() ? ChevronRightIcon : ChevronDownIcon} />
                    </th>
                    <th
                      colspan={2}
                      class={classes.municipiality}
                      style={{ "font-weight": "bold" }}
                      title={zipcode.municipiality}
                    >
                      {zipcode.municipiality}
                    </th>
                    <th style={{ "font-weight": "normal", "text-align": "end" }}>({zipcodeDrivers().length})</th>
                  </tr>
                </thead>
                <Show when={!isZipcodeCollapsed()}>
                  <tbody>
                    <For each={zipcodeDrivers()}>
                      {(driver) => {
                        return (
                          <>
                            <tr class="cursor-pointer align-middle">
                              <td onclick={() => props.onDriverClick?.(driver.driverId)}>
                                <div class={classes.badge} classList={{ [classes[driver.status]]: true }}>
                                  {driver.vehicleIdentifier}
                                </div>
                              </td>
                              <td
                                class={classes.name}
                                title={driver.driverName}
                                onclick={() => props.onDriverClick?.(driver.driverId)}
                              >
                                {driver.driverName}
                              </td>
                              <td
                                style={{ width: "1px" }}
                                class={classes.seats}
                                onclick={() => props.onDriverClick?.(driver.driverId)}
                              >
                                {driver.vehicleSeats}
                              </td>
                              <td
                                style={{ width: "20px" }}
                                class="text-primary cursor-pointer"
                                title={`${driver.phoneNumber} (${ct["common.rightClickToCopy"]()})`}
                                onclick={() => window.open(`tel:${driver.phoneNumber}`)}
                                oncontextmenu={async (e) => {
                                  e.preventDefault();
                                  await navigator.clipboard.writeText(driver.phoneNumber);
                                }}
                              >
                                <PhoneIcon size={20} />
                              </td>
                            </tr>
                          </>
                        );
                      }}
                    </For>
                  </tbody>
                </Show>
              </>
            );
          }}
        </For>
      </table>
    </>
  );
};
