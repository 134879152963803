import BusIcon from "lucide-solid/icons/bus";
import HotelIcon from "lucide-solid/icons/hotel";
import PlaneIcon from "lucide-solid/icons/plane";
import TrainFrontIcon from "lucide-solid/icons/train-front";
import * as API from "mooovex-api-schema";
import { Component, Match, Switch, splitProps } from "solid-js";
import { Dynamic } from "solid-js/web";

export const PlaceSearchIcon: Component<{
  place?: API.google_autocomplete.ResponseBodyItem;
  color?: string;
  class?: string;
  loading?: boolean;
}> = (props) => {
  const iconMap = new Map([
    ["aeroway:aerodrome", PlaneIcon],
    ["tourism:hotel", HotelIcon],
    ["highway:bus_stop", BusIcon],
    ["railway:station", TrainFrontIcon],
  ]);

  const classList = (defaultClasses?: string) => {
    let classes: Record<string, boolean> = {};
    defaultClasses
      ?.trim()
      .split(" ")
      .forEach((c) => (classes[c] = true));
    props.class
      ?.trim()
      .split(" ")
      .forEach((c) => (classes[c] = true));
    return classes;
  };

  function getIcon(input: string) {
    return (
      iconMap.get(input) ?? (() => <div style={{ color: iconProps.color }} classList={classList("bi-circle-fill")} />)
    );
  }

  const [iconProps] = splitProps(props, ["color", "class"]);

  return (
    <Switch fallback={<div style={{ color: iconProps.color }} classList={classList("bi-circle-fill")} />}>
      <Match when={props.loading}>
        <div
          style={{ color: iconProps.color, margin: "4px 0" }}
          classList={classList("spinner-grow spinner-grow-sm")}
        />
      </Match>
      <Match
        when={props.place && API.PlaceAdapter.isGh(props.place) && `${props.place.osm_key}:${props.place.osm_value}`}
      >
        {(place) => <Dynamic component={getIcon(place())} {...iconProps} />}
      </Match>
    </Switch>
  );
};
