import { Component, For, Show } from "solid-js";

import { useTaxistations } from "components";
import classes from "./Taxistations.module.scss";

export const TaxistationsC: Component = () => {
  const {
    taxistationsArray,
    setActiveTaxistationRequest,
    setEnabledTaxistationRequest,
    setEnabledTaxistationZipcodeRequest,
  } = useTaxistations();

  const zipcodes = () => {
    const zipcodesSet = new Set<number>();
    const zipcodesArray: Array<{ zipcodeId: number; zipcode: string; municipiality: string }> = [];

    for (const taxistation of taxistationsArray()) {
      if (!zipcodesSet.has(taxistation.zipcodeId)) {
        zipcodesSet.add(taxistation.zipcodeId);
        zipcodesArray.push({
          zipcodeId: taxistation.zipcodeId,
          zipcode: taxistation.zipcode,
          municipiality: taxistation.municipality,
        });
      }
    }

    return zipcodesArray;
  };

  return (
    <div class={classes.container}>
      <For each={zipcodes()}>
        {(zipcode) => {
          const zipcodeTaxistations = () =>
            taxistationsArray()
              .filter((taxistation) => taxistation.zipcodeId === zipcode.zipcodeId)
              .sort((a, b) => a.sortorder - b.sortorder);

          const zipcodeEnabled = () => zipcodeTaxistations().some((t) => t.enabled);
          return (
            <>
              <div class="d-flex">
                <div class="fw-bold flex-grow-1 text-truncate" title={zipcode.municipiality}>
                  {zipcode.municipiality}
                </div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input cursor-pointer"
                    type="checkbox"
                    checked={zipcodeEnabled()}
                    onclick={async () =>
                      await setEnabledTaxistationZipcodeRequest(zipcode.zipcodeId, !zipcodeEnabled())
                    }
                  />
                </div>
              </div>
              <ul class={`list-group mb-2 ${classes.listgroup}`}>
                <For each={zipcodeTaxistations()}>
                  {(taxistation) => (
                    <li class="list-group-item text-start py-0" classList={{ active: taxistation.active }}>
                      <div class="d-flex">
                        <div
                          class="flex-grow-1 text-truncate"
                          classList={{
                            "text-decoration-line-through": !taxistation.enabled,
                            "cursor-pointer": taxistation.enabled,
                          }}
                          onclick={async () =>
                            taxistation.enabled && (await setActiveTaxistationRequest(taxistation.id))
                          }
                          title={taxistation.name}
                        >
                          {taxistation.name}
                        </div>
                        <Show when={!taxistation.active}>
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input cursor-pointer"
                              type="checkbox"
                              checked={taxistation.enabled}
                              onclick={async () =>
                                await setEnabledTaxistationRequest(taxistation.id, !taxistation.enabled)
                              }
                            />
                          </div>
                        </Show>
                      </div>
                    </li>
                  )}
                </For>
              </ul>
            </>
          );
        }}
      </For>
    </div>
  );
};
