import { Component, Show } from "solid-js";
import { useTransferForm } from "../TransferFormProvider";
import { ct } from "../i18n";

type Props = {
  disabled?: boolean;
};

export const StartDateButton: Component<Props> = (props) => {
  const [transferForm, , { showDatePicker }] = useTransferForm();

  return (
    <button
      disabled={props.disabled}
      class="btn btn-lg flex-grow-1 flex-nowrap fw-bold"
      classList={{
        "btn-border-dark": !transferForm.form.startDate,
        "btn-primary": !!transferForm.form.startDate,
      }}
      onclick={() => showDatePicker(transferForm.form.startDate)}
    >
      <i class="bi-calendar-week" />
      <Show when={transferForm.form.startDate}>
        {(date) => <span class="ms-1">{ct.formatters.date(date().toDate())}</span>}
      </Show>
    </button>
  );
};
