import { useTransferForm } from "components/src/TransferFormProvider";
import PanelBottomCloseIcon from "lucide-solid/icons/panel-bottom-close";
import PanelBottomOpenIcon from "lucide-solid/icons/panel-bottom-open";
import { Component } from "solid-js";
import { Dynamic } from "solid-js/web";

type Props = {};

export const MapHideFormControl: Component<Props> = (props) => {
  const [transferForm, setTransferForm] = useTransferForm();

  return (
    <>
      <button
        type="button"
        class="btn btn-border-dark bg-white shadow p-0"
        onclick={() => setTransferForm("formVisible", (prev) => !prev)}
        style={{ height: "41px", width: "41px" }}
      >
        <Dynamic component={transferForm.formVisible ? PanelBottomCloseIcon : PanelBottomOpenIcon} />
      </button>
    </>
  );
};
