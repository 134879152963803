import XIcon from "lucide-solid/icons/x";
import { Component, Index, createSelector } from "solid-js";
import { useTransferForm } from "../TransferFormProvider";
import classes from "./PassengerCountSelectorOverlay.module.scss";

type Props = {
  max: number;
  min: number;
};

export const PassengerCountSelectorOverlay: Component<Props> = (props) => {
  const [transferForm, setTransferForm] = useTransferForm();
  const isSelected = createSelector(() => transferForm.form.passengerCount);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        "z-index": 4,
        "background-color": "white",
        transition: "transform 150ms ease-in-out",
        transform: `translateY(${transferForm.passengerCountVisible ? "0" : "100%"})`,
        overflow: "hidden",
        padding: "6px",
      }}
    >
      <div class="position-relative d-flex gap-2 justify-content-center align-items-center h-100">
        <XIcon
          class="position-absolute cursor-pointer"
          style={{ top: "6px", right: "6px" }}
          onclick={() => {
            setTransferForm("form", "passengerCount", undefined);
            setTransferForm("passengerCountVisible", false);
          }}
        />
        <div class={classes.container}>
          <Index each={new Array(props.max - props.min + 1)}>
            {(_, i) => {
              return (
                <button
                  class={`btn ${classes.button}`}
                  classList={{
                    "btn-primary": isSelected(i + props.min),
                    "btn-border-primary": !isSelected(i + props.min),
                  }}
                  onclick={() => {
                    setTransferForm("form", "passengerCount", i + props.min);
                    setTransferForm("passengerCountVisible", false);
                  }}
                >
                  {i + props.min}
                </button>
              );
            }}
          </Index>
        </div>
      </div>
    </div>
  );
};
