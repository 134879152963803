import { SignInWithPasswordCredentials, User } from "@supabase/supabase-js";
import { createSignal } from "solid-js";
import { liveLocationsCache, staffCache, supabase, transportcompaniesCache, vehiclesCache } from "./supabase";

const [userSignal, setUserSignal] = createSignal<User | undefined>();

export async function signInWithPassword(credentials: SignInWithPasswordCredentials) {
  const { error, data } = await supabase.auth.signInWithPassword(credentials);

  if (error) {
    throw error;
  }

  setUserSignal(data.user);
}

export async function signUp(
  email: string,
  password: string,
  metadata: { first_name: string; last_name: string; phone: string; locale: string }
) {
  const { data, error } = await supabase.auth.signUp({ email, password, options: { data: metadata } });

  if (error) throw error;

  setUserSignal(data.user ?? undefined);

  return data;
}

export async function signOut() {
  sessionStorage.clear();
  liveLocationsCache.clear();
  vehiclesCache.clear();
  staffCache.clear();
  transportcompaniesCache.clear();

  const { error } = await supabase.auth.signOut({ scope: "local" });
  if (error) {
    throw error;
  }
}

export async function sendPasswordResetEmail(email: string, redirectTo: string) {
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo,
  });
  if (error) {
    throw error;
  }
}

export async function changePassword(newPassword: string) {
  const { error, data } = await supabase.auth.updateUser({ password: newPassword });
  if (error) {
    throw error;
  }

  return data.user;
}

export async function markAccountForDeletion(password: string) {
  const { error, data } = await supabase.rpc("mark_user_for_deletion", { current_password: password });
  if (error) {
    throw error;
  }

  return data;
}

export { setUserSignal, userSignal };
